import axios from '@/common/axios'

export function selectToBeDealt () {
  return axios({
    method: 'get',
    url: '/workflow/selectToBeDealt/'
  })
}

export function selectMessage () {
  return axios({
    method: 'get',
    url: '/message/selectMessage/'
  })
}

<template>
  <div>
    <el-badge
      :value="todoList.length + messageList.length"
      class="item"
      :hidden="!messageList.length && !todoList.length"
    >
      <el-button
        class="share-button"
        icon="el-icon-bell"
        type="primary"
        @click="drawer = !drawer"
      />
    </el-badge>
    <el-drawer :visible.sync="drawer" :show-close="false" :with-header="false">
      <el-tabs
        v-model="activeName"
        style="padding: 0 10px"
        @tab-click="handleClick"
      >
        <el-tab-pane label="消息通知" name="first">
          <el-collapse style="overflow-y: auto; height: 100vh">
            <el-collapse-item
              v-for="item in messageList"
              :key="item.id"
              :title="item.name + '/' + item.message"
              :name="item.id"
            >
              <el-button
                type="text"
                icon="el-icon-check"
                size="small"
                @click.stop="handleReview(item)"
              >
                前往
              </el-button>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="待办任务" name="second">
          <el-collapse style="overflow-y: auto; height: 100vh">
            <el-collapse-item
              v-for="item in todoList"
              :key="item.id"
              :title="item.procName + '/' + item.starterName"
              :name="item.id"
            >
              <el-button
                type="text"
                icon="el-icon-check"
                size="small"
                @click.stop="handleReview(item)"
              >
                {{ item.procStepName }}
              </el-button>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>

<script>
import { selectToBeDealt, selectMessage } from '@/api/main/workflow'
import { Howl, Howler } from 'howler'
let voice = require('../assets/wechatMsg.mp3')
export default {
  data () {
    return {
      drawer: false,
      activeName: 'first',
      todoList: [],
      messageList: [],
      socketList: [],
      timer: null,
      noticeVoice: ''
    }
  },
  watch: {
    '$route.fullPath' (val) {
      if (val) {
        this.drawer = false
      }
    }
  },
  async mounted () {
    await this.initVoice()
    setTimeout(() => {
      this.getTotal()
      this.setTime()
    }, 2000)
  },
  destroyed () {
    console.log('组件销毁了')
    this.timer = null
  },
  methods: {
    handleClick (tab, event) {
      // console.log(tab, event);
    },
    setTime () {
      if (!this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.getTotal()
      }, 300000)
    },
    // getTodaList() {
    //   selectToBeDealt().then((res) => {
    //     this.todoList = res;
    //     if (res.length) {
    //       this.playSong();
    //     }
    //   });
    // },
    getTodaList () {
      return new Promise((resolve, reject) => {
        selectToBeDealt().then((res) => {
          resolve(res)
        })
      })
    },
    getMessageList () {
      return new Promise((resolve, reject) => {
        selectMessage()
          .then((res) => {
            resolve(res)
          })
          .catch((rej) => {
            resolve([])
          })
      })
    },
    async getTotal () {
      let data = await this.getTodaList()
      let data2 = await this.getMessageList()
      if (data.length || data2.length) {
        this.playSong()
      }
      this.todoList = data
      this.messageList = data2
    },
    handleReview (row) {
      if (row.type === 1) {
        this.$router.push(row.path)
      }
      //仓储
      if (row.procName === '出库单') {
        this.$router.push('/storage/orderOut')
      }
      if (row.procName === '请验单') {
        this.$router.push('/storage/orderCheck')
      }
      if (row.procName === '入库初检记录') {
        this.$router.push('/storage/inFirst')
      }
      if (row.procName === '出货单') {
        this.$router.push('/storage/DeliveryOrder')
      }
      //生产设备
      if (row.procName === '生产计划表') {
        this.$router.push('/produce/plan')
      }
      if (row.procName === '批生产指令通知单') {
        this.$router.push('/produce/batchInstructionNotice')
      }
      if (row.procName === '工作服、鞋清洗消毒记录') {
        this.$router.push('/produce/clothesDisinfection')
      }
      if (row.procName === '外来人员进出洁净室(区)登记表') {
        this.$router.push('/produce/outsidersCleanRoomRegister')
      }
      if (row.procName === '清洁工具清洁消毒记录') {
        this.$router.push('/produce/cleaningToolsDisinfection')
      }
      if (row.procName === '消毒剂配制和发放记录') {
        this.$router.push('/produce/disinfectantMakeIssue')
      }
      if (row.procName === '洗涤剂配制和发放记录') {
        this.$router.push('/produce/detergentMakeIssue')
      }
      //销售
      if (row.procName === '销货通知单') {
        this.$router.push('/sale/notice')
      }
      if (row.procName === '订单评审表') {
        this.$router.push('/sale/orderReview')
      }
      if (row.procName === '顾客反馈信息记录表') {
        this.$router.push('/sale/feedbackInfo')
      }
      if (row.procName === '顾客反馈调查记录') {
        this.$router.push('/sale/feedbackSurvey')
      }
      if (row.procName === '退换货申请单') {
        this.$router.push('/sale/returnApplication')
      }
      if (row.procName === '样品领用单') {
        this.$router.push('/sale/sampleRequisition')
      }
      //通用
      if (row.procName === '领料单') {
        this.$router.push('/universal/materialRequisition')
      }
      if (row.procName === '入库单') {
        this.$router.push('/universal/orderIn')
      }
      if (row.procName === '设施验收单') {
        this.$router.push('/universal/deviceCheck')
      }
      if (row.procName === '设备封存（启用）申请单') {
        this.$router.push('/universal/deviceSealEnable')
      }
      if (row.procName === '固定资产报废申请单') {
        this.$router.push('/universal/assetRetirement')
      }
      if (row.procName === '设备报废申请单') {
        this.$router.push('/universal/deviceRetirement')
      }
      if (row.procName === '质量信息反馈表') {
        this.$router.push('/universal/qualityInformationFeedback')
      }
      if (row.procName === '洁净区臭氧消毒记录') {
        this.$router.push('/produce/ozoneDisinfection')
      }
      if (row.procName === '设施设备维修保养记录') {
        this.$router.push('/produce/deviceMaintenance')
      }
      if (row.procName === '不合格品处理单') {
        this.$router.push('/quality/unqualifiedProducts')
      }
      if (row.procName === '采购申请单') {
        this.$router.push('/purchase/requisition')
      }
      if (row.procName === '消毒剂/洗涤剂领用申请表') {
        this.$router.push('/universal/disinfectantCollection')
      }
      //质量
      if (row.procName === '玻璃仪器校验台账') {
        this.$router.push('/quality/glassLedger')
      }
      if (row.procName === '沉降菌检测记录') {
        this.$router.push('/quality/settlingBacteriaRecord')
      }
      if (row.procName === '换气次数检测记录') {
        this.$router.push('/quality/airChangeRecord')
      }
      if (row.procName === '进货检验报告') {
        this.$router.push('/quality/incomingInspectionReport')
      }
      if (row.procName === '一次性使用手术电极过程检验记录') {
        this.$router.push('/quality/surgicalElectrodeTestRecord')
      }
      if (row.procName === '一次性使用中性电极过程检验记录') {
        this.$router.push('/quality/neutralElectrodeTestRecord')
      }
      if (row.procName === '初始污染菌检验报告') {
        this.$router.push('/quality/initialBacteriaReport')
      }
      if (row.procName === '微粒污染检验报告') {
        this.$router.push('/quality/particulatePollutionReport')
      }
      if (row.procName === '环氧乙烷残留量检验报告') {
        this.$router.push('/quality/ethyleneOxideReport')
      }
      if (row.procName === '无菌检验报告') {
        this.$router.push('/quality/sterilityReport')
      }
      if (row.procName === '微生物检验报告') {
        this.$router.push('/quality/microbeInspectionReport')
      }
      if (row.procName === '一次性使用手术电极出厂检验报告') {
        this.$router.push('/quality/surgicalElectrodeFactoryReport')
      }
      if (row.procName === '一次性使用中性电极出厂检验报告') {
        this.$router.push('/quality/neutralElectrodeFactoryReport')
      }
      if (row.procName === '成品放行审核单') {
        this.$router.push('/quality/finishedProductAudit')
      }
      if (row.procName === '培养基配制、灭菌记录') {
        this.$router.push('/quality/cultureMedium')
      }
      if (row.procName === '计量器具周期检定计划表') {
        this.$router.push('/quality/measuringToolsPlan')
      }
      if (row.procName === '实验中废弃的带菌物品灭菌处理记录') {
        this.$router.push('/quality/bacteriaSterilization')
      }
      if (row.procName === '稳定性试验工作计划表') {
        this.$router.push('/quality/stabilityTestWorkPlan')
      }
      if (row.procName === '纯化水检测记录') {
        this.$router.push('/quality/waterCheck')
      }
      if (row.procName === '纯化水检验报告') {
        this.$router.push('/quality/waterCheck')
      }
      if (row.procName === '留样考察记录') {
        this.$router.push('/quality/sampleInvestigation')
      }
      //采购
      if (row.procName === '采购计划表') {
        this.$router.push('/purchase/schedule')
      }
    },
    initVoice () {
      this.noticeVoice = new Howl({
        src: [voice],
        onload: function () {
          console.log('Finished!')
        }
      })
    },
    playSong () {
      this.noticeVoice.play()
    }
  }
}
</script>

<style>
.item {
  margin-top: 5px;
  margin-right: 10px;
  float: right;
}
.share-button {
  width: 36px;
  padding: 10px;
}
</style>

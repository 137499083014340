<template>
  <div>
    <template v-for="menu in menuTree">
      <el-menu-item
        v-if="!menu.children || menu.children.length === 0"
        :key="menu.id"
        :index="menu.route"
      >
        <i :class="menu.icon" />
        <span slot="title">{{ menu.name }}</span>
      </el-menu-item>
      <el-submenu
        v-if="menu.children && menu.children.length !== 0"
        :key="menu.id"
        :index="menu.id.toString()"
      >
        <template slot="title">
          <i :class="menu.icon" />
          <span>{{ menu.name }}</span>
        </template>
        <nav-menu :menu-tree="menu.children" />
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: {
    menuTree: {
      type: Array,
      required: true
    }
  }
}
</script>

<style>
</style>
